.container {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 16px;
}
/* end: Globals */

/* start: Payment */
.payment-section {
  padding: 48px 0;
}
/* .payment-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
} */
.payment-header {
  padding: 24px;
  background-color: var(--indigo-500);
  border-radius: 12px;
  padding-bottom: 72px;
}
.payment-header-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--indigo-600);
  color: var(--white);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.payment-header-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white);
  line-height: 1.4;
  margin-bottom: 4px;
}
.payment-header-description {
  font-size: 15px;
  color: var(--gray-200);
  line-height: 1.5;
}
.payment-content {
  padding: 24px;
  margin-top: -64px;
  position: relative;
}
.payment-content::before {
  content: '';
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  height: 16px;
  border-radius: 4px;
  background-color: var(--indigo-600);
}
.payment-body {
  background-color: var(--white);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, .05), inset 0 8px 0 rgba(0, 0, 0, .05);
  position: relative;
  padding-top: 8px;
  overflow: hidden;
}
.payment-plan {
  display: flex;
  align-items: center;
  padding: 12px;
}
.payment-plan-type {
  width: 40px;
  height: 40px;
  background-color: var(--indigo-500);
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 8px;
}
.payment-plan-info {
  width: 100%;
  margin-right: 8px;
  display: grid;
}
.payment-plan-info-name {
  font-size: 13px;
  color: var(--gray-400);
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.payment-plan-info-price {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.payment-plan-change {
  color: var(--blue-500);
  font-size: 12px;
  font-weight: 600;
  text-underline-offset: 2px;
}
.payment-plan-change:hover {
  color: var(--blue-600);
}
.payment-summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
}
.payment-summary-name {
  font-size: 14px;
  color: var(--gray-500);
}
.payment-summary-price {
  font-weight: 500;
  font-size: 15px;
}
.payment-summary-divider {
  width: calc(100% - 16px);
  height: 0;
  margin: 12px auto;
  border-bottom: 1px dashed var(--gray-200);
  position: relative;
}
.payment-summary-divider::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--gray-50);
  box-shadow: inset 0 2px 16px rgba(0, 0, 0, .05);
}
.payment-summary-divider::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--gray-50);
  box-shadow: inset 0 2px 16px rgba(0, 0, 0, .05);
}
.payment-summary-total {
  padding-bottom: 16px;
}
.payment-summary-total .payment-summary-name {
  color: var(--gray-900);
}
.payment-summary-total .payment-summary-price {
  font-size: 16px;
  color: var(--indigo-500);
  font-weight: 600;
}
.payment-right {
  min-width: 0;
}
.payment-form {
  padding: 24px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, .05);
}
.payment-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: 1.3;
}
.payment-method {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: auto;
  padding: 6px 0;
  margin-bottom: 12px;
  width: 100%;
}

.card-or-mobile {
  display: flex;
  width: 30% !important;
  min-width: none;
}
.payment-method input {
  display: none;
}
.payment-method-item {
  width: 80px;
  height: 80px;
  padding: 8px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}
input:checked + .payment-method-item {
  border-color: var(--indigo-500);
}
input:checked + .payment-method-item::before {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--indigo-500);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwLjAwMDcgMTUuMTcwOUwxOS4xOTMxIDUuOTc4NTJMMjAuNjA3MyA3LjM5MjczTDEwLjAwMDcgMTcuOTk5M0wzLjYzNjcyIDExLjYzNTRMNS4wNTA5MyAxMC4yMjEyTDEwLjAwMDcgMTUuMTcwOVoiIGZpbGw9InJnYmEoMjU1LDI1NSwyNTUsMSkiPjwvcGF0aD48L3N2Zz4=");
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}
.payment-method-item > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.payment-form-group {
  position: relative;
  margin-bottom: 16px;
}
.payment-form-control {
  outline: transparent;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  padding: 24px 16px 8px 16px;
  width: 100%;
  transition: all .15s ease-in-out;
}
.payment-form-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  color: var(--gray-400);
  pointer-events: none;
  transition: all .1s ease-in-out;
}
.payment-form-control:focus {
  outline: 1px solid var(--indigo-500);
  border-color: var(--indigo-500);
}
.payment-form-control:focus + .payment-form-label,
.payment-form-control:not(:placeholder-shown) + .payment-form-label {
  top: 30%;
  font-size: 12px;
}
.payment-form-label-required::after {
  content: ' *';
  color: var(--red-500);
}
.payment-form-group-flex {
  display: flex;
  align-items: center;
}
.payment-form-group-flex > * {
  width: 100%;
}
.payment-form-group-flex > :not(:last-child) {
  margin-right: 12px;
}
.payment-form-submit-button {
  background-color: #3b8c7d;
  border-radius: 8px;
  outline: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  cursor: pointer;
  color: var(--white);
  font-weight: 600;
  padding: 16px;
  transition: all .15s ease-in-out;
}
.payment-form-submit-button:hover {
  background-color: #2ca690;
}
.payment-form-submit-button > i {
  margin-right: 8px;
}
/* end: Payment */



/* start: Breakpoints */
@media screen and (max-width: 767px) {
  .payment-wrapper {
      grid-template-columns: 1fr;
  }
  .payment-content {
      padding: 16px;
  }
  .payment-content::before {
      top: 16px;
      width: calc(100% - 20px);
  }
  .payment-form-group-flex {
      display: block;
  }
  .card-or-mobile-method{
    justify-content: space-evenly;
  }
  .card-or-mobile{
    width: 45% !important;
    padding: 3px;
    border-radius: 2px;
  }
  .payment-method-item {
    width: 55px;
    height:55px;
  }
  .payment-method-item span{
    font-size: 10px;
  }
}
/* end: Breakpoints */