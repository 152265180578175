@media print {
    body {
        print-color-adjust: exact;
    }
}

.bg-receipt-blank {
    background-color: #efefef;
    position: relative;
}

.bg-receipt-content {
    background-color: white;
}

.recu-containDivImg {
    height: 4rem;
}

.recu-containDivImg-containImg {
    height: 100%;
}

.recu-containDivImg-containImg img {
    height: 100%;
    object-fit: contain;
}

.recu-notification {
    color: white;
    border-radius: 1rem;
    width: max-content;
}

.info-big-toPayment table td,
.info-big-toPayment table th {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.info-big-toPayment tbody td {
    font-weight: 500;
    color: #999999;
}

.info-big-toPayment {
    border-radius: 1rem;
}

.gradient-custom-1 {
    /* fallback for old browsers */
    background: #cd9cf2;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to top, rgba(205, 156, 242, 1), rgba(246, 243, 255, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to top, rgba(205, 156, 242, 1), rgba(246, 243, 255, 1))
}

.disabledMarginBottom {
    margin-bottom: 0 !important;
}

.receipt-containDivCodeQR {
    height: 5rem;
}

.receipt-containDivCodeQR-containCodeQR {
    height: 100%;
}

.footer-receipt {
    background: #000000;
    width: 100%;
    color: white;
    border-top: 1px solid #ddd;
}

.custom-bg {
    background-color: white;
}