input#phone{
	background-color: #ffffff;
	border: 2px solid #eee;
	border-radius: 0.5rem;
	padding: 1rem;
}

input#phone:focus{
	animation-duration: 0.5s;
	animation-name: ChangeBorder;
	animation-fill-mode: forwards;
}

@keyframes ChangeBorder {
	from{
		border: 2px solid #eee;
	}

	to{
		border: 3px solid #0755ca;
	}
}