@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Bitter-Regular";
  src: url("./fonts/bitter/Bitter-Regular.ttf");
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: black;
  margin: 0;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

input,
textarea,
select,
button {
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  /* margin: 0;  */
}

html body div.wrapper div.inner h2 {
  color: orange;
  text-align: center;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.wrapper {
  /* height: 100vh; */
  /* background: #f9f6f1; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* background: url("../images/form-wizard-bg.jpg"); */
  background-size: cover;
}

.inner {
  width: 909px;
}

.image-holder {
  position: relative;
  text-align: center;
}

.image-holder h3 {
  position: absolute;
  text-align: center;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h3 {
  font-family: "Bitter-Regular";
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  padding: 9px 23px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 400;
  letter-spacing: 0.3px;
}

.wizard {
  /* background: url("../images/form-content-bg.png") repeat; */
  /* padding: 62px 60px 58px 62px; */
  margin-top: 2%;
  display: flex;
}

.wizard .steps {
  width: 26.05%;
  /* margin-right: 68px; */
}

.wizard .content {
  width: 73.95%;
}
.side ul li{
  padding: 10px;
  font-weight: 600;
  line-height: 2;
}
.side ul .active{
  border-left: 5px solid #2b8f7c;
}
.steps ul {
  border-left: 3px solid rgba(242, 242, 242, 0.4);
}

.steps li {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: 31px;
  position: relative;
}

.steps li a {
  color: black;
  font-family: "Poppins-Medium";
  font-size: 15px;
  padding-left: 18px;
}

.steps li a:before {
  content: "";
  width: 3px;
  height: 31px;
  position: absolute;
  left: -3px;
  top: 0;
}

.steps li.current a {
  color: #0860a1;
}

.steps li.current a:before {
  background: #0860a1;
}

.content h4 {
  display: none;
}

label {
  margin-bottom: 7px;
  display: block;
  font-size: 14px;
}

.form-group .form-row {
  margin-bottom: 27px;
}

.form-row {
  /* display: flex; */
  margin-bottom: 29px;
}

.form-row.mb-21 {
  margin-bottom: 21px;
}

.form-row .form-holder,
.form-row .select {
  width: 50%;
  margin-right: 30px;
}

.form-row .form-holder:last-child,
.form-row .select:last-child {
  margin-right: 0;
}

.form-row .form-holder.w-100,
.form-row .select.w-100 {
  width: 100%;
  margin-right: 0;
}

.form-row .form-holder.mr-20,
.form-row .select.mr-20 {
  margin-right: 20px;
}

.form-row .select .form-holder {
  width: 100%;
  margin-right: 0;
}

.form-holder {
  position: relative;
}

.form-holder span.lnr-chevron-down {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 10px;
}

.form-holder span.lnr-calendar-full {
  position: absolute;
  bottom: 12px;
  right: 0;
  font-size: 12px;
}

.form-holder span.placeholder {
  position: absolute;
  bottom: 8px;
  left: 0;
  font-size: 14px;
}

.select {
  position: relative;
}

.select .select-control {
  height: 34px;
  border-bottom: 1px solid #5d718e;
  width: 100%;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.select .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  background: black;
  color: #999;
  z-index: 9;
  border: 1px solid #81acee;
}

.select .dropdown li {
  padding: 2px 10px;
}

.select .dropdown li:hover {
  background: #81acee;
  color: #fff;
}

.form-control {
  background: none;
  height: 34px;
  border: none;
  border-bottom: 1px solid #5d718e;
  width: 100%;
  font-size: 14px;
  padding: 0;
}

.form-control.pl-85 {
  padding-left: 85px;
}

.form-control.pl-96 {
  padding-left: 96px;
}

.form-control::-webkit-input-placeholder {
  color: black;
}

.form-control::-moz-placeholder {
  color: black;
}

.form-control:-ms-input-placeholder {
  color: black;
}

.form-control:-moz-placeholder {
  color: black;
}

.form-control:focus {
  border-bottom: 1px solid #e6e6e6;
}

textarea.form-control {
  padding: 6px 0;
}

.option {
  color: #999;
  padding-left: 20px;
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: #fff;
}

select.form-control option[value=""][disabled] {
  display: none;
}

select option {
  padding: 0 15px;
}

.section-style {
  display: flex;
}

.section-style .board-wrapper {
  width: 50%;
  margin-right: 30px;
}

.section-style .form-wrapper,
.section-style .pay-wrapper {
  width: 50%;
}

.board-inner {
  background: #fff;
  color: #012353;
  font-size: 14px;
  padding: 22px 33px 13px 21px;
}

.board-inner div {
  margin-bottom: 8px;
}

.board-inner div:last-child {
  margin-bottom: 0;
}

.board-inner .board-item span {
  margin-left: 13px;
}

.board-inner .board-line {
  display: flex;
  justify-content: space-between;
}

.board-inner .board-line div {
  margin-bottom: 0;
}

.bill {
  border: 1px solid #fff;
  padding: 18px 20px 11px 20px;
}

.bill .bill-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.bill .bill-item .price {
  font-family: "Poppins-Medium";
  color: #edc948;
}

.bill .bill-item.people {
  justify-content: flex-start;
}

.bill .bill-item.people .bill-unit:first-child {
  margin-right: 28px;
}

.bill .bill-item.service {
  margin-top: 31px;
}

.bill .bill-item.vat {
  margin-bottom: 14px;
}

.bill .bill-item.total-price {
  margin-bottom: 21px;
}

.bill .bill-item.total-price .price {
  font-size: 17px;
}

.bill .bill-item.total {
  font-size: 12px;
  align-items: center;
}

.bill .bill-item.total span {
  display: block;
  margin-left: 0;
  font-size: 14px;
}

.bill .bill-item.total .price {
  font-size: 17px;
}

.bill .bill-unit span {
  margin-left: 2px;
}

.bill .bill-cell {
  padding-bottom: 7px;
  border-bottom: 1px solid #5d718e;
  margin-bottom: 15px;
}

.bill .bill-cell:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

button {
  padding: 0;
  border: none;
  display: flex;
  height: 42px;
  width: 164px;
  justify-content: center;
  align-items: center;
  background: #56cc86;
  font-family: "Poppins-Medium";
  text-transform: uppercase;
  font-size: 14px;
  color: #FFF;
  cursor: pointer;
  bottom: 56px;
  font-weight: 500;
  margin-top: auto;
  font-weight: 500;
}

button i {
  margin-left: 10px;
}

button:hover {
  background: #56cc86;
  opacity: .9;
}

.checkbox {
  position: relative;
  padding-left: 25px;
}

.checkbox label {
  cursor: pointer;
}

.checkbox label a {
  color: #edc948;
}

.checkbox label a:hover {
  color: #d4b43f;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox input:checked~.checkmark:after {
  display: block;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 15px;
  border: 1px solid #fff;
  font-family: Material-Design-Iconic-Font;
  font-size: 13px;
}

.checkmark:after {
  position: absolute;
  top: 0;
  left: 1px;
  display: none;
  content: '\f26b';
  color: #fff;
}

.checkbox-circle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.checkbox-circle label {
  padding-left: 19px;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.checkbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-circle input:checked~.checkmark:after {
  display: block;
}

.checkbox-circle .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.checkbox-circle .checkmark:after {
  content: "";
  top: 3px;
  left: 3px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  display: none;
}

@media (max-width: 1500px) {
  .wrapper {
    height: auto;
    /* min-height: 100vh; */
    padding: 80px 0;
  }
}

@media (max-width: 1199px) {
  .wrapper {
    padding: 0;
  }
}

@media (max-width: 991px) {
  .wizard {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .wizard {
    padding: 50px 20px;
    flex-direction: column;
  }

  .wizard .steps {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .wizard .content {
    width: 100%;
  }

  .section-style {
    flex-direction: column;
  }

  .section-style .board-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }

  .section-style .form-wrapper,
  .section-style .pay-wrapper {
    width: 100%;
  }

  .form-row {
    display: block;
    margin-bottom: 0;
  }

  .form-row .form-holder {
    width: 100%;
    margin-right: 0;
  }

  .form-row .select {
    width: 100%;
    margin-right: 0;
  }

  .form-control,
  .select-control {
    margin-bottom: 29px;
  }

  .form-holder span.placeholder {
    bottom: 36px;
  }

  .form-holder span.lnr-chevron-down,
  .form-holder span.lnr-calendar-full {
    bottom: 39px;
  }

  .select span.lnr-chevron-down {
    bottom: 10px;
  }

  h3 {
    width: 90%;
    text-align: center;
  }
}

/*# sourceMappingURL=style.css.map */
/* 3688fc
0860a1 */

/* Start landing page */
.landing-page {
  min-height: 100vh;
  /* background-image: url("../img1/mobile.jpg");
  background-size: cover ; */
  position: relative;

}

.center-img {
  text-align: center;
}

.landing-page .overlay {
  /* background-color: #000; */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  /* z-index: 1; */

}

.header-area {
  position: relative;
  background-color: #2b8f7c;
  z-index: 2;
  color: #FFF;
  display: flex;
  /* padding: 10px; */

}

.header-area .logo {
  width: 250px;
  padding: 15px;
  font-weight: bold;
}

.header-area div.logo img {
  border-style: none;
  max-width: 50%;
  cursor: pointer;
}

.header-area .links {
  list-style: none;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  padding-left: 0px;
  font-size: 15px;
  margin-top: 25px;

}

.header-area .links li {
  display: inline-block;
  margin-left: 10px;
}

.header-area .links li a {
  color: #FFF;
  text-decoration: none;
  transition: .3s;

}

.header-area .links li a:hover,
.header-area .links li a:active {
  color: var(--main-color);

}

.welcome {
  text-align: center;
}

.footer {
  /* position: absolute;
  bottom: 0; */
  background: #2b8f7c;
  padding: 2em;
  text-align: center;
}

html body footer.footer.footer-alt {
  background: #2b8f7c;
  padding: 2em;
  text-align: center;
  color: #FFF;
}

/* Style the list */
ul.breadcrumb {
  padding: 10px 16px;
  margin-bottom: 15px;
  list-style: none;
  background-color: #0860a1;
  color: #FFF;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 8px;
  color: #FFF;
  content: "/";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #FFF;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #FFF;
  text-decoration: underline;
}

.inner>p:nth-child(2) {
  text-align: center;
}

.parallax {
  /* The image used */
  /* background-image: url("../images/mobile.jpg"); */

  /* Set a specific height */
  min-height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.25; */
}

.paragraph-center {
  text-align: center;
}

.line hr {
  background-color: #2b8f7c;
}

.fof{
  display: table-cell;
  vertical-align: middle;
  font-family: "Poppins-Regular" !important;
}

.fof h2{
  
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
  font-family: "Poppins-Regular"
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}